.wrapper {
    width: 90px;
    position: relative;
    z-index: 1;
}

.circle {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    background-color: #000;
    left: 15%;
    transform-origin: 50%;
    animation: circle7124 0.5s alternate infinite ease;
}

@keyframes circle7124 {
    0% {
        top: 20px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }

    40% {
        height: 10px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 1%;
    }
}

.circle:nth-child(2) {
    left: 45%;
    animation-delay: 0.2s;
}

.circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
}

.shadow {
    width: 10px;
    height: 2px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 22px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow046 0.5s alternate infinite ease;
}

@keyframes shadow046 {
    0% {
        transform: scaleX(1.5);
    }

    40% {
        transform: scaleX(1);
        opacity: 0.7;
    }

    100% {
        transform: scaleX(0.2);
        opacity: 0.4;
    }
}

.shadow:nth-child(4) {
    left: 45%;
    animation-delay: 0.2s;
}

.shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
}
