.status-atrasado {
    background-color: red;
    color: white;
}

.status-em-andamento {
    background-color: green;
    color: white;
}

.status-risco-atraso {
    background-color: #ffff00;
    color: black;
}
