.image-container {
    position: relative;
}

.shake-rotate {
    animation: shake-rotate-animation 0.5s ease-in-out;
}

@keyframes shake-rotate-animation {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
